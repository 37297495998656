.App {
  text-align: center;
}

body {
  /* background-color: #141f25; */
}

.modal-content {
  background-color: #3f4850;
  border-radius: 15px; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  margin: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
.in-app::-webkit-input-placeholder {
  color: "#fff";
  -webkit-appearance: none;
} */

input:focus, .updateUser:focus {
  border-color: transparent;
  outline: none;
}


.in-app:focus {
    background-color: #3a4863;
    border: 1px solid #3a4863;
    box-shadow: 5px 5px 5px 5px #3a4863;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  margin-top: 15px;
  width: 40px;
  height: 40px;
  border: 15px solid #dd6508; /* #d2d4d8;  #f3f3f3; */ /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

circle {
  fill: #141f25;
  stroke: url(#gradient);
  stroke-width: 3;
  stroke-dasharray: 176;
  stroke-dashoffset: 176;
  animation: anim 2s linear forwards;
  /* transform: rotate(-90deg); */
}

circle.idea {
  fill: transparent;
  stroke: url(#gradient);
  stroke-width: 8;
  stroke-dasharray: 289;
  stroke-dashoffset: 289;
  animation: anim2 2s linear forwards;
  /* transform: rotate(90deg); */
}


circle.mini {
  fill: transparent;
  stroke: transparent;
  stroke-width: 4;
  stroke-dasharray: 201;
  stroke-dashoffset: 201;
  animation: anim2 2s linear forwards;
  /* transform: rotate(90deg); */
}

@keyframes anim2 {
  100% {
    stroke-dasharray: 289;
  }
}

@keyframes anim {
  100% {
    stroke-dasharray: 176;
  }
}


@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}
@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}
@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}
.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0;
}
.confetti--animation-slow {
  animation: confetti-slow 2.25s linear 1 forwards;
}
.confetti--animation-medium {
  animation: confetti-medium 1.75s linear 1 forwards;
}
.confetti--animation-fast {
  animation: confetti-fast 1.25s linear 1 forwards;
}

body::-webkit-scrollbar, div::-webkit-scrollbar {
  scrollbar-color: #141f25 #141f25;
  
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
body::-webkit-scrollbar-track, div::-webkit-scrollbar-track, ::-webkit-scrollbar-track {
  background: #141f25 #141f25;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #141f25 #141f25;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #141f25;
}

/* .marker {
  transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 50%;
  border-radius: 50%;
  padding: 0 3px 3px 0;
  width: 40px;
  height: 40px;
  background: #ed1f34;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -2.2em 0 0 -1.3em;
  -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
  -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
  box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
} */

.marker {
  transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 50%;
  border-radius: 50%;
  border-radius: 50% 50% 50% 0;
  padding: 0 3px 3px 0;
  width: 40px;
  height: 40px;
  background: #ed1f34;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -2.2em 0 0 -1.3em;
  -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
  -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
  box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
}

/* .marker:after {
  content: '';
  width: 0.5em;
  height: 0.5em;
  margin: 1em 0 0 .7em;
  background: #ffffff;
  position: absolute;
  border-radius: 50%;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  -moz-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);
  -webkit-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);
  box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);
} */
.beacon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1
}

.beacon:before,
.beacon:after {
  position: absolute;
  content: "";
  height: 3em;
  width: 3em;
  left: 0;
  top: 0;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #ed1f34;
  -webkit-animation: active 2s infinite linear;
  -moz-animation: active 2s infinite linear;
  animation: active 2s infinite linear;
}

.beacon:after {
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes active {
  0% {
    -webkit-transform: scale(.1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes active {
  0% {
    transform: scale(.1);
    transform: box-shadow(0px 0px 0px 1px #ed1f34);
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}


.skeleton-light {
    opacity: 0.7;
    animation: skeleton-light 1s linear infinite alternate;
}

.skeleton-dark {
    opacity: 0.7;
    animation: skeleton-dark 1s linear infinite alternate;
}

@keyframes skeleton-light {
    0% {
        background-color: hsl(200, 11%, 84%);
    }
    
    100% {
        background-color: hsl(200, 11%, 95%);
    }
}

@keyframes skeleton-dark {
    0% {
        background-color: hsl(203, 31%, 15%);
    }

    100% {
        background-color: hsl(203, 31%, 5%);
    }
}

@font-face {
  font-family: "Montserrat";   /*Can be any text*/
  src: local("Montserrat"),
    url("/src/font/static/Montserrat-Medium.ttf") format("truetype");
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}


.tooltip:hover .tooltiptext {
  visibility: visible;
}

.app-container {
  margin-top: env(safe-area-inset-top);
  height: calc(100vh - env(safe-area-inset-top));
}

.recommendation-details {
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
}

.recommendation-title {
  font-size: 32px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 30px;
}

.recommendation-action {
  margin-bottom: 10px;
}

.recommendation-action b {
  font-weight: bold;
  margin-right: 10px;
}

.recommendation-action a {
  color: blue;
}